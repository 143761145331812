/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "El lector de pdf Adobe Reader no se encuentra en los repositorios de Debian, por eso para instalarlo iremos a la página de Adobe ", React.createElement(_components.a, {
    href: "http://www.adobe.com/es/"
  }, "www.adobe.com/es/"), " y buscaremos el boton Get Adobe Reader. La pagina automaticamente nos seleccionara el paquete rpm de Adobe Reader en español, pero nosotros queremos el deb por eso le daremos a “¿Diferentes idiomas o sistemas operativos?”, seleccionamos el que pone (.deb) y lo bajamos. Luego como root lo instalamos:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">$ dpkg -i AdobeReader_esp-8.1.4-1.i386.deb</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "Asi de sencillo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
